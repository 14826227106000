export function stringMaskFormat(value, mask) {
  if (!mask) return value;
  let output = '';
  let maskChars = 0;
  for (var i = 0; i < mask.length; i++) {
    const maskChar = mask.charAt(i);
    if (maskChar === ' ' || maskChar === '-' || maskChar === '(' || maskChar === ')') {
      output = output + maskChar;
      maskChars += 1;

    } else {
      output = output + value.charAt(i - maskChars);
    };
  }
  maskChars = 0
  return output;
}

// Resta el porcentaje indicado a un color (RR, GG o BB) hexadecimal para oscurecerlo
const subtractLight = function (color, amount) {
  let cc = parseInt(color, 16) - amount;
  let c = (cc < 0) ? 0 : (cc);
  c = (c.toString(16).length > 1) ? c.toString(16) : `0${c.toString(16)}`;
  return c;
}

// Oscurece un color hexadecimal de 6 caracteres #RRGGBB segun el porcentaje indicado
export const darken = (color, amount) => {
  color = (color?.indexOf("#") >= 0) ? color?.substring(1, color?.length) : color;
  amount = parseInt((255 * amount) / 100);
  return color = `#${subtractLight(color?.substring(0, 2), amount)}${subtractLight(color?.substring(2, 4), amount)}${subtractLight(color?.substring(4, 6), amount)}`;
}

export function mediaType(url) {
  if (!url) return "";

  try {
    const urlObj = new URL(url);
    const pathname = urlObj.pathname;
    const extension = pathname.split(".").pop()?.toLowerCase();

    if (["jpg", "jpeg", "png", "gif", "svg", "bmp", "webp"].includes(extension)) return "image";
    if (["mp4", "webm", "ogg", "ogv", "mov", "avi", "mkv", "m4v"].includes(extension)) return "video";
  } catch (e) {
    console.error("Invalid URL:", url);
  }

  return "";
}